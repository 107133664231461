.footer__img {
    width:100%;
}
.footer__img_2{
    width:100%;
}


.footer__title {
    color: #363736;
}

ul {
    align-self: start;
    list-style: none;
}

.footer_text {
    color: #000000;
    font-weight: 500;
    font-size:0.725rem;
}
.footer{
    background: #F1F1F1;
}

.footer-item-text {
    font-weight: 500;
    font-size:0.725rem;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  
    .footer__img_2{
        width:50%;
        margin:auto;
        display:block;
    }

    .footer__img {
        width:50%;
        margin:auto;
        display:block;
    }
    
  }