.como {
  background-color: #55A3EB;
  padding:2% 5%;
  margin-bottom: 10% !important;
}
.como_title {
  color:white;
}
.como-img {
  position: relative;
  width:100%;
  top:25%
}

.card-img-1 {
width:50%;
}

.card-img-2 {
  width:25%;
}

.programas-presu {
  display:block;
  background-color: #E16163;
}

.programa-presu-title {
  color:white;
}

.programa-presu-subtitle {
  color:white;
}

.card-acordeon {
  background-color: transparent !important;
}
.card-header {
  background-color: transparent;
  border-bottom-color: white;
}

.card-header {
  border-bottom-color: white;
  border-bottom-width: 2px;
}

.acordeon-card-title {
  color:white;
}
.acordeon-card-text {
  color:white;
}

.fuente-clasi{
  font-size:1.5rem;
}

.icono-flecha-abajo{
  width:16px;
  margin:3px
}

.financiacion__fuente_title{
  font-size:2.0rem;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .card-img-1 {
    width:70%;
    }
    
    .card-img-2 {
      width:75% !important;
    }
    .title-princi {
      font-size:2rem;
    }

    .como_title {
      padding-top: 15%;
      color: white;
  }

  .como {
    background-color: #55A3EB;
    padding: 10% 5%;
    margin-bottom: 10% !important;
}
  }
  