.navbar__title {
  color: #363736 !important;
  font-family: 'Helsinki';
  line-height: 28px;
  font-size: 1.500em;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.05em !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.navbar_callToAction {
  color:white;
}

.button__secondary_nav   {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  /*   font-family: 'Helsinki'; */
  background: #55a3eb;
  color: #ffffff !important;
  line-height: 12px;
  font-size: 14px;
  padding: 0.6em 1.5em;
  border: none;
  text-decoration: none;
  vertical-align: middle;
}
.button__secondary_nav:hover,
.button__secondary_nav:active,
.button__secondary_nav:focus {
  background: #cbe776 !important;
  border: none !important;
}

a.dropdown-toggle {
  color: #ffffff !important;
}


.navbar__item {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  color:  #363736;
  font-weight: 500;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0 1rem;
  /* padding-left:6em !important; */
  position: relative;

}

.navbar__item_boton {
  
  color:  white !important;
  font-weight: 500;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0 1rem;
  /* padding-left:6em !important; */
  position: relative;

}



.navbar__item .arrow {
  display: none;
  position: absolute;
  bottom: -1.4rem;
  left: 50%;
  transform: translateX(-50%);
}

.navbar__item:hover .arrow {
  display: block;
}

.nav-link-color {
  color:#363736 !important;
}

.dropdown-item:active {
  color: #fff;
    text-decoration: none;
    background-color: white;
}

@media only screen and (max-width: 768px) {
  .navbar__title {
    font-size: 1em;
  }
  .navbar-toggler {
    border:none;
  }
  .navbar__item .arrow {
    display: none;
    position: absolute;
    bottom: -1.4rem;
    left: 75%;
    transform: translateX(-50%);
  }
 
}