.ods-banner {
  
  background-color:#55A3EB;
 
}

.ods-banner-img {
  position: relative;
  width:100%;
  top:20%
}

.ods-banner-title {
  color:white;
}
.img-ods-impacto {
  width:50%;
}

@media only screen and (max-width: 768px) {
  .ods-banner-title {
    padding-top:20%;
  }
  .img-ods-impacto {
    width:100%;
  }
  
 
}