.banner {
  background-color: #e16163;
  padding-top: 8% !important;
  margin-bottom: 5% !important;
}

.banner__img {
  width: 80%;
  position: relative;
  /*  top: 50% !important; */
}

.banner__text {
  align-self: center;
  padding-bottom: 10%;
}

.banner__text_title_bold {
  font-size: 1.563rem;
  line-height: 30px;
  text-align: left;
  font-weight: bold;
  color: white !important;
}
.banner__text_title_normal {
  font-size: 1.563rem;
  line-height: 32px;
  text-align: left;
  color: white !important;
}

.banner__text_subtitle {
  font-size: 1.25rem;
  line-height: 32px;
  font-weight: bold;
  color: white !important;
}

.button__primary {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  background: #cbe776;
  color: #ffffff;
  /* font-family: 'Helsinki'; */
  line-height: 12px;
  font-size: 14px;
  padding: 0.6rem 1.5rem;
  border: none;
  text-decoration: none;
}
.button__primary:hover,
.button__primary:active,
.button__primary:focus {
  background: #55a3eb !important;
  text-decoration: none;
  border: none !important;
}

.banner__preparar {
  padding-top: 0% !important;
  padding-left: 10% !important;
  background-color: #e16163;
  margin-bottom: 15% !important;
}

.familia__text {
  align-self: center;
}

.familia__text_title {
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
  font-size: 25px;
  color: white;
}

.familia_text_title_span {
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase !important;
}
.familia__img {
  width: 100%;
  position: relative;
  top: 45%;
}

.banner__elabora {
  margin-bottom: 15% !important;
}

.banner__elabora_title {
  text-align: center !important;
  font-size: 25px;
  font-weight: 500;
  line-height: 25px;
  color: #363736;
}

.banner__elabora_title_span {
  font-weight: bold;
  text-transform: uppercase;
}

.card__img {
  width: 100%;
}

.banner__cumplir {
  background-color: #e16163;
  padding: 15% 10%;
  margin-bottom: 15%;
}
.banner__cumplir__img {
  width: 75%;
}

.banner__cumplir_text {
  align-self: center;
}

.banner__cumplir_text_title {
  text-align: right;
  font-size: 25px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  font-style: normal;
}

.banner__conoce {
  margin-bottom: 5%;
}

.banner__conoce_title {
  font-size: 24px;
  line-height: 30px;
  color: #363736;
  margin-bottom: 5%;
}

.card {
  border: none;
}

.card__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #363736;
}

.presupuesto_total {
  background-color: #55a3eb;
}
.presupuesto_total_ministerios {
  background-color: #e16163;
}

.presupuesto_total__title {
  color: white !important;
  line-height: 33px;
  font-size: 28px;
  font-weight: bold;
}

.banner_presupuesto_total__title {
  color: white !important;
  line-height: auto;
  font-size: 26px;
  font-weight: bold;
}

.banner_presupuesto_total__title2 {
  color: white !important;
  line-height: auto;
  font-size: 18px;
  font-weight: bold;
}

.banner_presupuesto_total__subtitle {
  font-weight: normal;
  color: white !important;
  font-size: 18px;
  line-height: auto;
}

.banner_presupuesto_total__subtitle_number {
  font-weight: normal;
  color: white !important;
  font-size: 18px;
  line-height: auto;
}

.presupuesto_total__subtitle {
  font-weight: bold;
  color: white !important;
  font-size: 1.563rem;
  line-height: 56px;
  text-align: center;
}

.presupuesto_total__subtitle_secondary {
  font-weight: 400;
  color: white !important;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
}
.presupuesto_total__subtitle_number {
  font-weight: bold;
  color: white !important;
  font-size: 1.375rem;
  line-height: 56px;
  text-align: center;
}

.presupuesto_total__card_img {
  align-self: center;
  width: 50%;
}

.icon {
  width: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.presupuesto_total__card_title {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: white !important;
}

.presupuesto_total__card__title_two {
  color: white !important;
  line-height: 33px;
  font-size: 24px;
  font-weight: bold;
}

.presupuesto_total__subtitle_two {
  font-weight: bold;
  color: white !important;
  font-size: 24px;
  line-height: 56px;
  text-align: center;
}

.presupuesto_total__card_monto_primary {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: white !important;
}

.presupuesto_total__card_monto_secondary {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: white !important;
}

.button__secondary {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  /*   font-family: 'Helsinki'; */
  background: #55a3eb;
  color: #ffffff;
  line-height: 12px;
  font-size: 14px;
  padding: 0.6rem 1.5rem;
  border: none;
  text-decoration: none;
}
.button__secondary:hover,
.button__secondary:active,
.button__secondary:focus {
  background: #cbe776 !important;
  border: none !important;
}



.information {
  background: #cbe776;
}

.information__title {
  font-size: 24px;
  line-height: 30px;
  color: #363736;
  font-weight: bold;
}

.information__subtitle {
  color: #363736;
  line-height: 18px;
  font-size: 15px;
  font-weight: 400;
}

.item_ods {
  background: #55a3eb !important;
  margin-bottom: 2em !important;
  border: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  color: white;
  text-align: center;
}

.list-group-item.active {
  background: #cbe776 !important;
}

/* Slides */
.slide-title {
  color: black;
  font-weight: bold;
}

.slide-text {
  color: black;
}

.slide-text-side {
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.carousel-control-next {
  right: 0;
  color: black;
}

.carousel-control-next-icon {
  background-image: url("../../assets/icons/arrow-right-solid.svg") !important;
}

.carousel-control-prev-icon {
  background-image: url("../../assets/icons/arrow-left-solid.svg") !important;
}

@media only screen and (max-width: 600px) {
  .carousel-item {
    height: 100vh !important;
  }
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.tarjeta {
  flex: 0 0 auto;
  margin-left: 5px;
  margin-right: 15px;
}

.tarjeta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  background: #ffffff;
  margin-top: 20px;
  border-radius: 20px;
  width: 200px;
  height: 220px;
  text-align: center;
  padding: 0px 20px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
}
.tarjeta:hover {
  transform: scale(1.1);
  background-color: #55a3eb;
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  margin-top: 40px;
  height: 320px;
  margin-bottom: 0px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.timeline {
  background: #cbe776;
  scrollbar-width: thin;
}

.tarjeta-title {
  padding-top: 10px;
  font-size: 1.063em;
}

.tarjeta-text {
  font-size: 0.813em;
}

.timeline ::-webkit-scrollbar {
  /* width: 10px; */
  width: 2em;
  height: 0.75em;
  /* width: 1em; */
  margin: 0;
}

/* Track */
.timeline ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
  margin: 0;
}

/* Handle */
.timeline ::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  margin: 0;
}

/* Handle on hover */
.timeline ::-webkit-scrollbar-thumb:hover {
  background: gray;
  margin: 0;
}

.card-ministerio {
  background-color: transparent;
  text-align: center;
}

.como-se-elabora {
  font-weight: bold;
  color: #363736 !important;
  font-size: 1.563rem;
  line-height: 56px;
  text-align: center;
}

.banner_presupuesto_total {
  background-color: #55a3eb;
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 768px) {
  .banner__text_title {
    font-size: 1.25em;
  }
  .banner__text {
    padding-top: 2.5%;
  }
  .information__title {
    padding-top: 20px;
  }

  .banner__text_title_bold {
    font-size: 1.063rem;
    line-height: auto;
    text-align: left;
    font-weight: bold;
    color: white !important;
  }

  .banner__text {
    padding-bottom: 2.5%;
  }

  .banner__text_title_normal {
    font-size: 0.963rem;
    line-height: auto;
    text-align: left;
    color: white !important;
  }
}
