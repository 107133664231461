.cambios_banner {
  background-color: #55A3EB;
}
.cambios_banner_title {
  color:white;
  /* position:relative;
  top:150%; */

}

.cambios_banner_img {
  text-align: center
}


.clasif {
  background-color: #E16163; 
  color:white;
}

.clasif-2 {
  background-color: #CBE776; 
  color:black;
}

.clasi_item_title {
  font-size:1.5625rem;
  font-weight: 700;


}

.color_a {
  color:white;
}

.clasi-item-1 {
  font-size:1.25rem;
  
}
.clasi-item-2 {
  font-size:1.25rem;
  padding-left:2em !important;
}
.clasi-item-3 {
  font-size:1.25rem;
  padding-left:4em !important;
}
.program-column {
  align-self: center;
} 

.program-title {
  text-align: right !important;
  font-size:1.5625rem;
}

.program-item {
  font-size:1.25rem;
}

.versus-anho {
  font-size:3.125em;
  color: #CBE776;
}

.versus-columna {

  color:white;
  background-color: #55A3EB;
  
}

.versus-columna h3 {
  font-size:1rem;
  font-weight: 700;
}

.versus-columna p {
  font-size:0.8125rem;
  font-weight: 500;
}


.central {
  margin:50% 0%;
}



 .boton-descarga {
  
  background: #E16163;
  
 
} 


.rotate {
  transform:          rotate(0deg);
  -ms-transform:      rotate(0deg);
-moz-transform:     rotate(0deg);
-webkit-transform:  rotate(0deg);
-o-transform:       rotate(0deg);
}

.borde-rojo {
  padding-bottom:3%;
  border-bottom : #E16163 2px solid;
}

.img-palacio {
  width:25%;
}

.img__cambios__palacio {
  width:50%;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cambios__title {
  font-size: 28px;
  line-height: 30px;
  color: #363736;
  font-weight: bold;
}
.cambios__subtitle {
  font-size: 22px;
  line-height: 30px;
  color: #363736;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .cambios_banner_title {
    color:white;
    position:relative;
    top:0%;
  }

  
.img__cambios__palacio {
  width:50%;
}

  .img__cambios {
    width:50%;
  }

  .img-palacio {
    width:50%;
  }

  .rotate {
    transform:          rotate(90deg);
    -ms-transform:      rotate(90deg);
-moz-transform:     rotate(90deg);
-webkit-transform:  rotate(90deg);
-o-transform:       rotate(90deg);
  }
  
 
}