.bannerTop{
  background-color: #55A3EB;
  padding:2% 5%;
  margin-bottom: 10% !important;
}

.bannerTopTitle{
  color:white;
}

.bannerTopImg {
  position:relative;
  top:40%;
  width:100%;
}

.buttonPrimary {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25) !important;
  background-color:#55A3EB !important;
  border:none !important;
  border-radius: 0%;
  
}

.font-size-procentaje {
  font-size:0.75em;
}

@media(max-width:768px){
  .bannerTopImg {
    position:relative;
    top:40%;
    width:50%;
  }
}